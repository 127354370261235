/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation ActivityCreateAdmin($data: ActivityInput!) {\n    activityCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ActivityCreateAdminDocument,
    "\n  mutation ActivityDeleteAdmin($activityDeleteId: Int!) {\n    activityDelete(id: $activityDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ActivityDeleteAdminDocument,
    "\n  query ActivityDetailAdmin($id: Int!) {\n    activity(id: $id) {\n      id\n      date\n      endTime\n      startTime\n      totalMinutes\n      service {\n        id\n        name\n        client {\n          id\n          name\n        }\n        sectorCode\n        users {\n          name\n        }\n      }\n      user {\n        name\n      }\n      staff {\n        id\n        name\n        email\n        isArchived\n      }\n    }\n  }\n": types.ActivityDetailAdminDocument,
    "\n  query ActivityEdit($id: Int!) {\n    activity(id: $id) {\n      date\n      endTime\n      startTime\n      service {\n        id\n        name\n        clientId\n        sectorCode\n      }\n      staff {\n        name\n        id\n      }\n      user {\n        name\n        id\n      }\n    }\n  }\n": types.ActivityEditDocument,
    "\n  mutation ActivityUpdateAdmin($activityUpdateId: Int!, $data: ActivityInput!) {\n    activityUpdate(id: $activityUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ActivityUpdateAdminDocument,
    "\n  query ActivityCalendarAdmin($dateFrom: Date!, $dateTo: Date!) {\n    activityCalendar(dateFrom: $dateFrom, dateTo: $dateTo) {\n      days\n    }\n  }\n": types.ActivityCalendarAdminDocument,
    "\n  query ReportAccount {\n    account {\n      email\n    }\n  }\n": types.ReportAccountDocument,
    "\n  mutation ReportSend($data: ReportInput!) {\n    reportSend(data: $data)\n  }\n": types.ReportSendDocument,
    "\n  query ActivityListAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        staffId\n        serviceId\n        serviceUserId\n        date\n        startTime\n        endTime\n        isArchived\n        createDate\n        updateDate\n        staff {\n          email\n          name\n        }\n        service {\n          id\n          name\n          client {\n            id\n            name\n          }\n          sectorCode\n          users {\n            name\n          }\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n": types.ActivityListAdminDocument,
    "\n  mutation ClientCreate($data: ServiceClientInput!) {\n    serviceClientCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ClientCreateDocument,
    "\n  query ActivityListServiceClientDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n": types.ActivityListServiceClientDetailAdminDocument,
    "\n  mutation ServiceClientDeleteAdmin($serviceClientDeleteId: Int!) {\n    serviceClientDelete(id: $serviceClientDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ServiceClientDeleteAdminDocument,
    "\n  query ServiceClientAdmin($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n": types.ServiceClientAdminDocument,
    "\n  query ClientEdit($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n": types.ClientEditDocument,
    "\n  mutation ClientUpdate($serviceClientUpdateId: Int!, $data: ServiceClientInput!) {\n    serviceClientUpdate(id: $serviceClientUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ClientUpdateDocument,
    "\n  query ServiceClientList($pagination: PaginationInput, $filters: ServiceClientFilters, $sort: ServiceClientSort!) {\n    serviceClientList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n": types.ServiceClientListDocument,
    "\n  mutation ExpirationCreate($data: ExpirationData!) {\n    expirationCreate(data: $data) {\n      object {\n        id\n        date\n        notify\n        title\n      }\n      errors\n    }\n  }\n": types.ExpirationCreateDocument,
    "\n  mutation ExpirationDelete($id: Int!) {\n    expirationDelete(id: $id) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ExpirationDeleteDocument,
    "\n  query ExpirationDetailAdmin($id: Int!) {\n    expiration(id: $id) {\n      id\n      date\n      notify\n      title\n      staff {\n        id\n        email\n        name\n      }\n    }\n  }\n": types.ExpirationDetailAdminDocument,
    "\n  query ExpirationAdmin($id: Int!) {\n    expiration(id: $id) {\n      id\n      staff {\n        id\n      }\n      date\n      notify\n      title\n    }\n  }\n": types.ExpirationAdminDocument,
    "\n  mutation ExpirationUpdateAdmin($id: Int!, $data: ExpirationData!) {\n    expirationUpdate(id: $id, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ExpirationUpdateAdminDocument,
    "\n  query expirationListAdmin($pagination: PaginationInput, $filters: ExpirationFilters, $sort: ExpirationSort!) {\n    expirationList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        notify\n        staff {\n          id\n          name\n        }\n        title\n      }\n      count\n      numPages\n    }\n  }\n": types.ExpirationListAdminDocument,
    "\n  query FileDetailAdmin($googleDriveFileId: String!) {\n    googleDriveFile(id: $googleDriveFileId) {\n      id\n      name\n      sharedWithCount\n      createDate\n      webViewLink\n      Subject\n      visibility\n      sharedWith\n    }\n  }\n": types.FileDetailAdminDocument,
    "\n  query FileListAdmin($nextPageToken: String, $filters: GoogleDriveFileFilters) {\n    googleDriveFileList(nextPageToken: $nextPageToken, filters: $filters) {\n      nextPageToken\n      files {\n        id\n        name\n        createDate\n        visibility\n      }\n    }\n  }\n": types.FileListAdminDocument,
    "\n  mutation AccessTokenCreate($googleToken: String!) {\n    accessTokenCreate(googleToken: $googleToken) {\n      object {\n        token\n      }\n      errors\n    }\n  }\n": types.AccessTokenCreateDocument,
    "\n  mutation ServiceCreate($data: ServiceInput!) {\n    serviceCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ServiceCreateDocument,
    "\n  query ActivityListServiceDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n": types.ActivityListServiceDetailAdminDocument,
    "\n  mutation ServiceDelete($serviceDeleteId: Int!) {\n    serviceDelete(id: $serviceDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ServiceDeleteDocument,
    "\n  query ServiceDetailAdmin($id: Int!) {\n    service(id: $id) {\n      id\n      name\n      client {\n        name\n        id\n      }\n      sectorCode\n      users {\n        id\n        name\n      }\n    }\n  }\n": types.ServiceDetailAdminDocument,
    "\n  query ServiceEdit($id: Int!) {\n    service(id: $id) {\n      id\n      name\n      clientId\n      sectorCode\n      users {\n        name\n      }\n    }\n  }\n": types.ServiceEditDocument,
    "\n  mutation ServiceUpdate($serviceUpdateId: Int!, $data: ServiceInput!) {\n    serviceUpdate(id: $serviceUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ServiceUpdateDocument,
    "\n  mutation ServiceClientCreate($data: ServiceClientInput!) {\n    serviceClientCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ServiceClientCreateDocument,
    "\n  query ServiceList($pagination: PaginationInput, $filters: ServiceFilters, $sort: ServiceSort!) {\n    serviceList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n        client {\n          name\n        }\n        users {\n          name\n        }\n        sectorCode\n      }\n      count\n      numPages\n    }\n  }\n": types.ServiceListDocument,
    "\n  mutation ServiceUserCreate($data: ServiceUserInput!) {\n    serviceUserCreate(data: $data) {\n      object {\n        id\n        name\n      }\n      errors\n    }\n  }\n": types.ServiceUserCreateDocument,
    "\n  mutation ServiceUserDelete($serviceUserDeleteId: Int!) {\n    serviceUserDelete(id: $serviceUserDeleteId) {\n      errors\n    }\n  }\n": types.ServiceUserDeleteDocument,
    "\n  mutation ServiceUserUpdate($serviceUserUpdateId: Int!, $data: ServiceUserInput!) {\n    serviceUserUpdate(id: $serviceUserUpdateId, data: $data) {\n      object {\n        id\n        name\n      }\n      errors\n    }\n  }\n": types.ServiceUserUpdateDocument,
    "\n  query SearchUser($filters: ServiceUserFilters!, $pagination: PaginationInput!) {\n    serviceUserList(filters: $filters, pagination: $pagination) {\n      objects {\n        name\n        id\n      }\n    }\n  }\n": types.SearchUserDocument,
    "\n  query ActivityListStaffDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n": types.ActivityListStaffDetailAdminDocument,
    "\n  mutation StaffUpdateAdmin($id: Int!, $data: StaffData!) {\n    staffUpdate(id: $id, data: $data) {\n      errors\n    }\n  }\n": types.StaffUpdateAdminDocument,
    "\n  query StaffDetailAdmin($id: Int!) {\n    staff(id: $id) {\n      id\n      email\n      name\n      imageUrl\n      code\n      voucher\n      isAspa\n      isEnabledManualActivity\n      isBlocked\n      isArchived\n      createDate\n      updateDate\n    }\n  }\n": types.StaffDetailAdminDocument,
    "\n  mutation StaffRefreshFromGoogleAdmin($id: Int!) {\n    staffRefreshFromGoogle(id: $id) {\n      errors\n    }\n  }\n": types.StaffRefreshFromGoogleAdminDocument,
    "\n  mutation StaffToggleBlockAdmin($id: Int!) {\n    staffToggleBlock(id: $id) {\n      object {\n        isBlocked\n      }\n      errors\n    }\n  }\n": types.StaffToggleBlockAdminDocument,
    "\n  query StaffListAdmin($pagination: PaginationInput, $filters: StaffFilters, $sort: StaffSort!) {\n    staffList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        email\n        name\n        imageUrl\n        code\n        voucher\n        isBlocked\n        isArchived\n        createDate\n        updateDate\n      }\n      count\n      numPages\n    }\n  }\n": types.StaffListAdminDocument,
    "\n  mutation UserCreate($data: ServiceUserInput!) {\n    serviceUserCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.UserCreateDocument,
    "\n  query ActivityListServiceUserDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n": types.ActivityListServiceUserDetailAdminDocument,
    "\n  mutation ServiceUserDeleteAdmin($serviceUserDeleteId: Int!) {\n    serviceUserDelete(id: $serviceUserDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ServiceUserDeleteAdminDocument,
    "\n  query ServiceUserAdmin($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n": types.ServiceUserAdminDocument,
    "\n  query UserEdit($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n": types.UserEditDocument,
    "\n  mutation UserUpdate($serviceUserUpdateId: Int!, $data: ServiceUserInput!) {\n    serviceUserUpdate(id: $serviceUserUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.UserUpdateDocument,
    "\n  query ServiceUserList($pagination: PaginationInput, $filters: ServiceUserFilters, $sort: ServiceUserSort!) {\n    serviceUserList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n        service {\n          id\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n": types.ServiceUserListDocument,
    "\n  mutation ActivityCreate($data: ActivityInput!) {\n    activityCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ActivityCreateDocument,
    "\n  mutation ActivityDeleteEmployee($activityDeleteId: Int!) {\n    activityDelete(id: $activityDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ActivityDeleteEmployeeDocument,
    "\n  query ActivityDetailEmployee($id: Int!) {\n    activity(id: $id) {\n      id\n      date\n      isEditable\n      endTime\n      startTime\n      totalMinutes\n      service {\n        id\n        name\n        fullName\n        client {\n          id\n          name\n        }\n        sectorCode\n      }\n      user {\n        name\n      }\n    }\n  }\n": types.ActivityDetailEmployeeDocument,
    "\n  query ActivityEditEmployee($id: Int!) {\n    activity(id: $id) {\n      date\n      endTime\n      startTime\n      user {\n        id\n        name\n      }\n      service {\n        id\n        name\n        clientId\n        sectorCode\n        users {\n          name\n        }\n      }\n    }\n  }\n": types.ActivityEditEmployeeDocument,
    "\n  mutation ActivityUpdateEmployee($activityUpdateId: Int!, $data: ActivityInput!) {\n    activityUpdate(id: $activityUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n": types.ActivityUpdateEmployeeDocument,
    "\n  query SubAccountForm {\n    account {\n      isEnabledManualActivity\n    }\n  }\n": types.SubAccountFormDocument,
    "\n  query AspaAccount {\n    account {\n      email\n    }\n  }\n": types.AspaAccountDocument,
    "\n  mutation ReportSendAspa($staffEmail: String!, $selectedUsers: [Int!]!, $month: Int!, $year: Int!, $type: AspaReportType!) {\n    reportSendAspa(staffEmail: $staffEmail, selectedUsers: $selectedUsers, month: $month, year: $year, type: $type)\n  }\n": types.ReportSendAspaDocument,
    "\n  query SubAccount {\n    account {\n      isAspa\n      isEnabledManualActivity\n    }\n  }\n": types.SubAccountDocument,
    "\n  mutation ReportSendStaffMonthlyAdm($staffEmail: String!, $serviceId: Int!, $serviceUserId: Int!, $month: Int!, $year: Int!, $aspa: Boolean!) {\n    reportSendStaffMonthlyAdm(staffEmail: $staffEmail, serviceId: $serviceId, serviceUserId: $serviceUserId, month: $month, year: $year, aspa: $aspa)\n  }\n": types.ReportSendStaffMonthlyAdmDocument,
    "\n  mutation ReportSendStaffMonthly($staffEmail: String!, $serviceId: Int!, $serviceUserId: Int!, $month: Int!, $year: Int!) {\n    reportSendStaffMonthly(staffEmail: $staffEmail, serviceId: $serviceId, serviceUserId: $serviceUserId, month: $month, year: $year)\n  }\n": types.ReportSendStaffMonthlyDocument,
    "\n  query ActivityListEmployee($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        serviceId\n        serviceUserId\n        date\n        startTime\n        endTime\n        isArchived\n        createDate\n        updateDate\n        totalHours\n        service {\n          id\n          name\n          fullName\n          clientId\n          sectorCode\n          users {\n            name\n          }\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n    activityTotals(filters: $filters) {\n      minutes\n      hoursFormatted\n    }\n  }\n": types.ActivityListEmployeeDocument,
    "\n  query ExpirationDetailEmployee($id: Int!) {\n    expiration(id: $id) {\n      id\n      date\n      notify\n      title\n      staff {\n        id\n        email\n        name\n      }\n    }\n  }\n": types.ExpirationDetailEmployeeDocument,
    "\n  query expirationListemployee($pagination: PaginationInput, $filters: ExpirationFilters, $sort: ExpirationSort!) {\n    expirationList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        notify\n        title\n      }\n      count\n      numPages\n    }\n  }\n": types.ExpirationListemployeeDocument,
    "\n  query FileDetailStaff($googleDriveFileId: String!) {\n    googleDriveFile(id: $googleDriveFileId) {\n      id\n      name\n      createDate\n      webViewLink\n      visibility\n    }\n  }\n": types.FileDetailStaffDocument,
    "\n  query FileListStaff($nextPageToken: String, $filters: GoogleDriveFileFilters) {\n    googleDriveFileList(nextPageToken: $nextPageToken, filters: $filters) {\n      nextPageToken\n      files {\n        id\n        name\n        createDate\n        visibility\n      }\n    }\n  }\n": types.FileListStaffDocument,
    "\n  mutation ActivityStamp($data: ActivityStampInput!) {\n    activityStamp(data: $data) {\n      object {\n        isStart\n        activity {\n          id\n          date\n          startTime\n          endTime\n        }\n      }\n      errors\n    }\n  }\n": types.ActivityStampDocument,
    "\n  query Account {\n    account {\n      imageUrl\n      email\n      name\n    }\n  }\n": types.AccountDocument,
    "\n  query ServiceListSearch($filters: ServiceFilters, $pagination: PaginationInput) {\n    serviceList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n        client {\n          name\n        }\n      }\n    }\n  }\n": types.ServiceListSearchDocument,
    "\n  query ServiceSearchDetail($id: Int!) {\n    service(id: $id) {\n      id\n      name\n    }\n  }\n": types.ServiceSearchDetailDocument,
    "\n  query ServiceClientListSearch($pagination: PaginationInput, $filters: ServiceClientFilters, $sort: ServiceClientSort!) {\n    serviceClientList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n": types.ServiceClientListSearchDocument,
    "\n  query ServiceClientSearch($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n": types.ServiceClientSearchDocument,
    "\n  query ServiceUserListSearch($pagination: PaginationInput, $filters: ServiceUserFilters, $sort: ServiceUserSort!) {\n    serviceUserList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n": types.ServiceUserListSearchDocument,
    "\n  query ServiceUserSearch($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n    }\n  }\n": types.ServiceUserSearchDocument,
    "\n  query StaffSearch($filters: StaffFilters, $pagination: PaginationInput) {\n    staffList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n      }\n    }\n  }\n": types.StaffSearchDocument,
    "\n  query StaffSearchDetail($id: Int!) {\n    staff(id: $id) {\n      id\n      name\n    }\n  }\n": types.StaffSearchDetailDocument,
    "\n  query UserListSearch($filters: ServiceUserFilters, $pagination: PaginationInput) {\n    serviceUserList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n        service {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.UserListSearchDocument,
    "\n  query UserSearchDetail($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n": types.UserSearchDetailDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ActivityCreateAdmin($data: ActivityInput!) {\n    activityCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ActivityCreateAdmin($data: ActivityInput!) {\n    activityCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ActivityDeleteAdmin($activityDeleteId: Int!) {\n    activityDelete(id: $activityDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ActivityDeleteAdmin($activityDeleteId: Int!) {\n    activityDelete(id: $activityDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityDetailAdmin($id: Int!) {\n    activity(id: $id) {\n      id\n      date\n      endTime\n      startTime\n      totalMinutes\n      service {\n        id\n        name\n        client {\n          id\n          name\n        }\n        sectorCode\n        users {\n          name\n        }\n      }\n      user {\n        name\n      }\n      staff {\n        id\n        name\n        email\n        isArchived\n      }\n    }\n  }\n"): (typeof documents)["\n  query ActivityDetailAdmin($id: Int!) {\n    activity(id: $id) {\n      id\n      date\n      endTime\n      startTime\n      totalMinutes\n      service {\n        id\n        name\n        client {\n          id\n          name\n        }\n        sectorCode\n        users {\n          name\n        }\n      }\n      user {\n        name\n      }\n      staff {\n        id\n        name\n        email\n        isArchived\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityEdit($id: Int!) {\n    activity(id: $id) {\n      date\n      endTime\n      startTime\n      service {\n        id\n        name\n        clientId\n        sectorCode\n      }\n      staff {\n        name\n        id\n      }\n      user {\n        name\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query ActivityEdit($id: Int!) {\n    activity(id: $id) {\n      date\n      endTime\n      startTime\n      service {\n        id\n        name\n        clientId\n        sectorCode\n      }\n      staff {\n        name\n        id\n      }\n      user {\n        name\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ActivityUpdateAdmin($activityUpdateId: Int!, $data: ActivityInput!) {\n    activityUpdate(id: $activityUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ActivityUpdateAdmin($activityUpdateId: Int!, $data: ActivityInput!) {\n    activityUpdate(id: $activityUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityCalendarAdmin($dateFrom: Date!, $dateTo: Date!) {\n    activityCalendar(dateFrom: $dateFrom, dateTo: $dateTo) {\n      days\n    }\n  }\n"): (typeof documents)["\n  query ActivityCalendarAdmin($dateFrom: Date!, $dateTo: Date!) {\n    activityCalendar(dateFrom: $dateFrom, dateTo: $dateTo) {\n      days\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReportAccount {\n    account {\n      email\n    }\n  }\n"): (typeof documents)["\n  query ReportAccount {\n    account {\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReportSend($data: ReportInput!) {\n    reportSend(data: $data)\n  }\n"): (typeof documents)["\n  mutation ReportSend($data: ReportInput!) {\n    reportSend(data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityListAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        staffId\n        serviceId\n        serviceUserId\n        date\n        startTime\n        endTime\n        isArchived\n        createDate\n        updateDate\n        staff {\n          email\n          name\n        }\n        service {\n          id\n          name\n          client {\n            id\n            name\n          }\n          sectorCode\n          users {\n            name\n          }\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ActivityListAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        staffId\n        serviceId\n        serviceUserId\n        date\n        startTime\n        endTime\n        isArchived\n        createDate\n        updateDate\n        staff {\n          email\n          name\n        }\n        service {\n          id\n          name\n          client {\n            id\n            name\n          }\n          sectorCode\n          users {\n            name\n          }\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClientCreate($data: ServiceClientInput!) {\n    serviceClientCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ClientCreate($data: ServiceClientInput!) {\n    serviceClientCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityListServiceClientDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ActivityListServiceClientDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceClientDeleteAdmin($serviceClientDeleteId: Int!) {\n    serviceClientDelete(id: $serviceClientDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceClientDeleteAdmin($serviceClientDeleteId: Int!) {\n    serviceClientDelete(id: $serviceClientDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceClientAdmin($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query ServiceClientAdmin($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClientEdit($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query ClientEdit($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClientUpdate($serviceClientUpdateId: Int!, $data: ServiceClientInput!) {\n    serviceClientUpdate(id: $serviceClientUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ClientUpdate($serviceClientUpdateId: Int!, $data: ServiceClientInput!) {\n    serviceClientUpdate(id: $serviceClientUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceClientList($pagination: PaginationInput, $filters: ServiceClientFilters, $sort: ServiceClientSort!) {\n    serviceClientList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ServiceClientList($pagination: PaginationInput, $filters: ServiceClientFilters, $sort: ServiceClientSort!) {\n    serviceClientList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ExpirationCreate($data: ExpirationData!) {\n    expirationCreate(data: $data) {\n      object {\n        id\n        date\n        notify\n        title\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation ExpirationCreate($data: ExpirationData!) {\n    expirationCreate(data: $data) {\n      object {\n        id\n        date\n        notify\n        title\n      }\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ExpirationDelete($id: Int!) {\n    expirationDelete(id: $id) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ExpirationDelete($id: Int!) {\n    expirationDelete(id: $id) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ExpirationDetailAdmin($id: Int!) {\n    expiration(id: $id) {\n      id\n      date\n      notify\n      title\n      staff {\n        id\n        email\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ExpirationDetailAdmin($id: Int!) {\n    expiration(id: $id) {\n      id\n      date\n      notify\n      title\n      staff {\n        id\n        email\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ExpirationAdmin($id: Int!) {\n    expiration(id: $id) {\n      id\n      staff {\n        id\n      }\n      date\n      notify\n      title\n    }\n  }\n"): (typeof documents)["\n  query ExpirationAdmin($id: Int!) {\n    expiration(id: $id) {\n      id\n      staff {\n        id\n      }\n      date\n      notify\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ExpirationUpdateAdmin($id: Int!, $data: ExpirationData!) {\n    expirationUpdate(id: $id, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ExpirationUpdateAdmin($id: Int!, $data: ExpirationData!) {\n    expirationUpdate(id: $id, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query expirationListAdmin($pagination: PaginationInput, $filters: ExpirationFilters, $sort: ExpirationSort!) {\n    expirationList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        notify\n        staff {\n          id\n          name\n        }\n        title\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query expirationListAdmin($pagination: PaginationInput, $filters: ExpirationFilters, $sort: ExpirationSort!) {\n    expirationList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        notify\n        staff {\n          id\n          name\n        }\n        title\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FileDetailAdmin($googleDriveFileId: String!) {\n    googleDriveFile(id: $googleDriveFileId) {\n      id\n      name\n      sharedWithCount\n      createDate\n      webViewLink\n      Subject\n      visibility\n      sharedWith\n    }\n  }\n"): (typeof documents)["\n  query FileDetailAdmin($googleDriveFileId: String!) {\n    googleDriveFile(id: $googleDriveFileId) {\n      id\n      name\n      sharedWithCount\n      createDate\n      webViewLink\n      Subject\n      visibility\n      sharedWith\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FileListAdmin($nextPageToken: String, $filters: GoogleDriveFileFilters) {\n    googleDriveFileList(nextPageToken: $nextPageToken, filters: $filters) {\n      nextPageToken\n      files {\n        id\n        name\n        createDate\n        visibility\n      }\n    }\n  }\n"): (typeof documents)["\n  query FileListAdmin($nextPageToken: String, $filters: GoogleDriveFileFilters) {\n    googleDriveFileList(nextPageToken: $nextPageToken, filters: $filters) {\n      nextPageToken\n      files {\n        id\n        name\n        createDate\n        visibility\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AccessTokenCreate($googleToken: String!) {\n    accessTokenCreate(googleToken: $googleToken) {\n      object {\n        token\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation AccessTokenCreate($googleToken: String!) {\n    accessTokenCreate(googleToken: $googleToken) {\n      object {\n        token\n      }\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceCreate($data: ServiceInput!) {\n    serviceCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceCreate($data: ServiceInput!) {\n    serviceCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityListServiceDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ActivityListServiceDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceDelete($serviceDeleteId: Int!) {\n    serviceDelete(id: $serviceDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceDelete($serviceDeleteId: Int!) {\n    serviceDelete(id: $serviceDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceDetailAdmin($id: Int!) {\n    service(id: $id) {\n      id\n      name\n      client {\n        name\n        id\n      }\n      sectorCode\n      users {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ServiceDetailAdmin($id: Int!) {\n    service(id: $id) {\n      id\n      name\n      client {\n        name\n        id\n      }\n      sectorCode\n      users {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceEdit($id: Int!) {\n    service(id: $id) {\n      id\n      name\n      clientId\n      sectorCode\n      users {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ServiceEdit($id: Int!) {\n    service(id: $id) {\n      id\n      name\n      clientId\n      sectorCode\n      users {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceUpdate($serviceUpdateId: Int!, $data: ServiceInput!) {\n    serviceUpdate(id: $serviceUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceUpdate($serviceUpdateId: Int!, $data: ServiceInput!) {\n    serviceUpdate(id: $serviceUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceClientCreate($data: ServiceClientInput!) {\n    serviceClientCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceClientCreate($data: ServiceClientInput!) {\n    serviceClientCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceList($pagination: PaginationInput, $filters: ServiceFilters, $sort: ServiceSort!) {\n    serviceList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n        client {\n          name\n        }\n        users {\n          name\n        }\n        sectorCode\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ServiceList($pagination: PaginationInput, $filters: ServiceFilters, $sort: ServiceSort!) {\n    serviceList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n        client {\n          name\n        }\n        users {\n          name\n        }\n        sectorCode\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceUserCreate($data: ServiceUserInput!) {\n    serviceUserCreate(data: $data) {\n      object {\n        id\n        name\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceUserCreate($data: ServiceUserInput!) {\n    serviceUserCreate(data: $data) {\n      object {\n        id\n        name\n      }\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceUserDelete($serviceUserDeleteId: Int!) {\n    serviceUserDelete(id: $serviceUserDeleteId) {\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceUserDelete($serviceUserDeleteId: Int!) {\n    serviceUserDelete(id: $serviceUserDeleteId) {\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceUserUpdate($serviceUserUpdateId: Int!, $data: ServiceUserInput!) {\n    serviceUserUpdate(id: $serviceUserUpdateId, data: $data) {\n      object {\n        id\n        name\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceUserUpdate($serviceUserUpdateId: Int!, $data: ServiceUserInput!) {\n    serviceUserUpdate(id: $serviceUserUpdateId, data: $data) {\n      object {\n        id\n        name\n      }\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchUser($filters: ServiceUserFilters!, $pagination: PaginationInput!) {\n    serviceUserList(filters: $filters, pagination: $pagination) {\n      objects {\n        name\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchUser($filters: ServiceUserFilters!, $pagination: PaginationInput!) {\n    serviceUserList(filters: $filters, pagination: $pagination) {\n      objects {\n        name\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityListStaffDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ActivityListStaffDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StaffUpdateAdmin($id: Int!, $data: StaffData!) {\n    staffUpdate(id: $id, data: $data) {\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation StaffUpdateAdmin($id: Int!, $data: StaffData!) {\n    staffUpdate(id: $id, data: $data) {\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query StaffDetailAdmin($id: Int!) {\n    staff(id: $id) {\n      id\n      email\n      name\n      imageUrl\n      code\n      voucher\n      isAspa\n      isEnabledManualActivity\n      isBlocked\n      isArchived\n      createDate\n      updateDate\n    }\n  }\n"): (typeof documents)["\n  query StaffDetailAdmin($id: Int!) {\n    staff(id: $id) {\n      id\n      email\n      name\n      imageUrl\n      code\n      voucher\n      isAspa\n      isEnabledManualActivity\n      isBlocked\n      isArchived\n      createDate\n      updateDate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StaffRefreshFromGoogleAdmin($id: Int!) {\n    staffRefreshFromGoogle(id: $id) {\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation StaffRefreshFromGoogleAdmin($id: Int!) {\n    staffRefreshFromGoogle(id: $id) {\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StaffToggleBlockAdmin($id: Int!) {\n    staffToggleBlock(id: $id) {\n      object {\n        isBlocked\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation StaffToggleBlockAdmin($id: Int!) {\n    staffToggleBlock(id: $id) {\n      object {\n        isBlocked\n      }\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query StaffListAdmin($pagination: PaginationInput, $filters: StaffFilters, $sort: StaffSort!) {\n    staffList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        email\n        name\n        imageUrl\n        code\n        voucher\n        isBlocked\n        isArchived\n        createDate\n        updateDate\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query StaffListAdmin($pagination: PaginationInput, $filters: StaffFilters, $sort: StaffSort!) {\n    staffList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        email\n        name\n        imageUrl\n        code\n        voucher\n        isBlocked\n        isArchived\n        createDate\n        updateDate\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UserCreate($data: ServiceUserInput!) {\n    serviceUserCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserCreate($data: ServiceUserInput!) {\n    serviceUserCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityListServiceUserDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ActivityListServiceUserDetailAdmin($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        startTime\n        endTime\n        staff {\n          name\n        }\n        service {\n          name\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ServiceUserDeleteAdmin($serviceUserDeleteId: Int!) {\n    serviceUserDelete(id: $serviceUserDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ServiceUserDeleteAdmin($serviceUserDeleteId: Int!) {\n    serviceUserDelete(id: $serviceUserDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceUserAdmin($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ServiceUserAdmin($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserEdit($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserEdit($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UserUpdate($serviceUserUpdateId: Int!, $data: ServiceUserInput!) {\n    serviceUserUpdate(id: $serviceUserUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserUpdate($serviceUserUpdateId: Int!, $data: ServiceUserInput!) {\n    serviceUserUpdate(id: $serviceUserUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceUserList($pagination: PaginationInput, $filters: ServiceUserFilters, $sort: ServiceUserSort!) {\n    serviceUserList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n        service {\n          id\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ServiceUserList($pagination: PaginationInput, $filters: ServiceUserFilters, $sort: ServiceUserSort!) {\n    serviceUserList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n        service {\n          id\n          name\n        }\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ActivityCreate($data: ActivityInput!) {\n    activityCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ActivityCreate($data: ActivityInput!) {\n    activityCreate(data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ActivityDeleteEmployee($activityDeleteId: Int!) {\n    activityDelete(id: $activityDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ActivityDeleteEmployee($activityDeleteId: Int!) {\n    activityDelete(id: $activityDeleteId) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityDetailEmployee($id: Int!) {\n    activity(id: $id) {\n      id\n      date\n      isEditable\n      endTime\n      startTime\n      totalMinutes\n      service {\n        id\n        name\n        fullName\n        client {\n          id\n          name\n        }\n        sectorCode\n      }\n      user {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ActivityDetailEmployee($id: Int!) {\n    activity(id: $id) {\n      id\n      date\n      isEditable\n      endTime\n      startTime\n      totalMinutes\n      service {\n        id\n        name\n        fullName\n        client {\n          id\n          name\n        }\n        sectorCode\n      }\n      user {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityEditEmployee($id: Int!) {\n    activity(id: $id) {\n      date\n      endTime\n      startTime\n      user {\n        id\n        name\n      }\n      service {\n        id\n        name\n        clientId\n        sectorCode\n        users {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ActivityEditEmployee($id: Int!) {\n    activity(id: $id) {\n      date\n      endTime\n      startTime\n      user {\n        id\n        name\n      }\n      service {\n        id\n        name\n        clientId\n        sectorCode\n        users {\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ActivityUpdateEmployee($activityUpdateId: Int!, $data: ActivityInput!) {\n    activityUpdate(id: $activityUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ActivityUpdateEmployee($activityUpdateId: Int!, $data: ActivityInput!) {\n    activityUpdate(id: $activityUpdateId, data: $data) {\n      errors\n      object {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SubAccountForm {\n    account {\n      isEnabledManualActivity\n    }\n  }\n"): (typeof documents)["\n  query SubAccountForm {\n    account {\n      isEnabledManualActivity\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AspaAccount {\n    account {\n      email\n    }\n  }\n"): (typeof documents)["\n  query AspaAccount {\n    account {\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReportSendAspa($staffEmail: String!, $selectedUsers: [Int!]!, $month: Int!, $year: Int!, $type: AspaReportType!) {\n    reportSendAspa(staffEmail: $staffEmail, selectedUsers: $selectedUsers, month: $month, year: $year, type: $type)\n  }\n"): (typeof documents)["\n  mutation ReportSendAspa($staffEmail: String!, $selectedUsers: [Int!]!, $month: Int!, $year: Int!, $type: AspaReportType!) {\n    reportSendAspa(staffEmail: $staffEmail, selectedUsers: $selectedUsers, month: $month, year: $year, type: $type)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SubAccount {\n    account {\n      isAspa\n      isEnabledManualActivity\n    }\n  }\n"): (typeof documents)["\n  query SubAccount {\n    account {\n      isAspa\n      isEnabledManualActivity\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReportSendStaffMonthlyAdm($staffEmail: String!, $serviceId: Int!, $serviceUserId: Int!, $month: Int!, $year: Int!, $aspa: Boolean!) {\n    reportSendStaffMonthlyAdm(staffEmail: $staffEmail, serviceId: $serviceId, serviceUserId: $serviceUserId, month: $month, year: $year, aspa: $aspa)\n  }\n"): (typeof documents)["\n  mutation ReportSendStaffMonthlyAdm($staffEmail: String!, $serviceId: Int!, $serviceUserId: Int!, $month: Int!, $year: Int!, $aspa: Boolean!) {\n    reportSendStaffMonthlyAdm(staffEmail: $staffEmail, serviceId: $serviceId, serviceUserId: $serviceUserId, month: $month, year: $year, aspa: $aspa)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReportSendStaffMonthly($staffEmail: String!, $serviceId: Int!, $serviceUserId: Int!, $month: Int!, $year: Int!) {\n    reportSendStaffMonthly(staffEmail: $staffEmail, serviceId: $serviceId, serviceUserId: $serviceUserId, month: $month, year: $year)\n  }\n"): (typeof documents)["\n  mutation ReportSendStaffMonthly($staffEmail: String!, $serviceId: Int!, $serviceUserId: Int!, $month: Int!, $year: Int!) {\n    reportSendStaffMonthly(staffEmail: $staffEmail, serviceId: $serviceId, serviceUserId: $serviceUserId, month: $month, year: $year)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActivityListEmployee($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        serviceId\n        serviceUserId\n        date\n        startTime\n        endTime\n        isArchived\n        createDate\n        updateDate\n        totalHours\n        service {\n          id\n          name\n          fullName\n          clientId\n          sectorCode\n          users {\n            name\n          }\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n    activityTotals(filters: $filters) {\n      minutes\n      hoursFormatted\n    }\n  }\n"): (typeof documents)["\n  query ActivityListEmployee($pagination: PaginationInput, $filters: ActivityFilters, $sort: ActivitySort!) {\n    activityList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        serviceId\n        serviceUserId\n        date\n        startTime\n        endTime\n        isArchived\n        createDate\n        updateDate\n        totalHours\n        service {\n          id\n          name\n          fullName\n          clientId\n          sectorCode\n          users {\n            name\n          }\n        }\n        user {\n          name\n        }\n      }\n      count\n      numPages\n    }\n    activityTotals(filters: $filters) {\n      minutes\n      hoursFormatted\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ExpirationDetailEmployee($id: Int!) {\n    expiration(id: $id) {\n      id\n      date\n      notify\n      title\n      staff {\n        id\n        email\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ExpirationDetailEmployee($id: Int!) {\n    expiration(id: $id) {\n      id\n      date\n      notify\n      title\n      staff {\n        id\n        email\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query expirationListemployee($pagination: PaginationInput, $filters: ExpirationFilters, $sort: ExpirationSort!) {\n    expirationList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        notify\n        title\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query expirationListemployee($pagination: PaginationInput, $filters: ExpirationFilters, $sort: ExpirationSort!) {\n    expirationList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        date\n        notify\n        title\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FileDetailStaff($googleDriveFileId: String!) {\n    googleDriveFile(id: $googleDriveFileId) {\n      id\n      name\n      createDate\n      webViewLink\n      visibility\n    }\n  }\n"): (typeof documents)["\n  query FileDetailStaff($googleDriveFileId: String!) {\n    googleDriveFile(id: $googleDriveFileId) {\n      id\n      name\n      createDate\n      webViewLink\n      visibility\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FileListStaff($nextPageToken: String, $filters: GoogleDriveFileFilters) {\n    googleDriveFileList(nextPageToken: $nextPageToken, filters: $filters) {\n      nextPageToken\n      files {\n        id\n        name\n        createDate\n        visibility\n      }\n    }\n  }\n"): (typeof documents)["\n  query FileListStaff($nextPageToken: String, $filters: GoogleDriveFileFilters) {\n    googleDriveFileList(nextPageToken: $nextPageToken, filters: $filters) {\n      nextPageToken\n      files {\n        id\n        name\n        createDate\n        visibility\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ActivityStamp($data: ActivityStampInput!) {\n    activityStamp(data: $data) {\n      object {\n        isStart\n        activity {\n          id\n          date\n          startTime\n          endTime\n        }\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation ActivityStamp($data: ActivityStampInput!) {\n    activityStamp(data: $data) {\n      object {\n        isStart\n        activity {\n          id\n          date\n          startTime\n          endTime\n        }\n      }\n      errors\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Account {\n    account {\n      imageUrl\n      email\n      name\n    }\n  }\n"): (typeof documents)["\n  query Account {\n    account {\n      imageUrl\n      email\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceListSearch($filters: ServiceFilters, $pagination: PaginationInput) {\n    serviceList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n        client {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ServiceListSearch($filters: ServiceFilters, $pagination: PaginationInput) {\n    serviceList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n        client {\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceSearchDetail($id: Int!) {\n    service(id: $id) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query ServiceSearchDetail($id: Int!) {\n    service(id: $id) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceClientListSearch($pagination: PaginationInput, $filters: ServiceClientFilters, $sort: ServiceClientSort!) {\n    serviceClientList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ServiceClientListSearch($pagination: PaginationInput, $filters: ServiceClientFilters, $sort: ServiceClientSort!) {\n    serviceClientList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceClientSearch($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query ServiceClientSearch($id: Int!) {\n    serviceClient(id: $id) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceUserListSearch($pagination: PaginationInput, $filters: ServiceUserFilters, $sort: ServiceUserSort!) {\n    serviceUserList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n"): (typeof documents)["\n  query ServiceUserListSearch($pagination: PaginationInput, $filters: ServiceUserFilters, $sort: ServiceUserSort!) {\n    serviceUserList(pagination: $pagination, filters: $filters, sort: $sort) {\n      objects {\n        id\n        name\n      }\n      count\n      numPages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ServiceUserSearch($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query ServiceUserSearch($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query StaffSearch($filters: StaffFilters, $pagination: PaginationInput) {\n    staffList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query StaffSearch($filters: StaffFilters, $pagination: PaginationInput) {\n    staffList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query StaffSearchDetail($id: Int!) {\n    staff(id: $id) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query StaffSearchDetail($id: Int!) {\n    staff(id: $id) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserListSearch($filters: ServiceUserFilters, $pagination: PaginationInput) {\n    serviceUserList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n        service {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserListSearch($filters: ServiceUserFilters, $pagination: PaginationInput) {\n    serviceUserList(filters: $filters, pagination: $pagination) {\n      objects {\n        id\n        name\n        service {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserSearchDetail($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserSearchDetail($id: Int!) {\n    serviceUser(id: $id) {\n      id\n      name\n      service {\n        id\n        name\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;