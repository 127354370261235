import React, { FC } from 'react';
import { FiSend } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  FormLabel,
  Icon,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import ServiceAutocomplete, { ServiceSearch } from '@common/components/ServiceAutocomplete';
import ServiceUserAutocomplete, { ServiceUserSearch } from '@common/components/ServiceUserAutocomplete';
import { graphql } from '@gql/gql';

interface Props extends ButtonProps {
  isAspa: boolean;
}

const ReportAdmButton: FC<Props> = ({ isAspa, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [service, setService] = React.useState<ServiceSearch | null>(null);
  const [serviceUser, setServiceUser] = React.useState<ServiceUserSearch | null>(null);
  const [month, setMonth] = React.useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = React.useState<number>(new Date().getFullYear());

  const { data } = useQuery(REPORT_ACCOUNT_QUERY);
  const [reportSendStaffMonthly, { loading: reportSendLoading }] = useMutation(REPORT_MUTATION);

  const handleDownload = React.useCallback(() => {
    if (service && serviceUser && month && year) {
      reportSendStaffMonthly({
        variables: {
          staffEmail: '',
          serviceId: service.id,
          serviceUserId: serviceUser.id,
          month: month,
          year: year,
          aspa: isAspa,
        },
      })
        .then(() => {
          toast.success('Report inviato');
        })
        .catch((error) => {
          console.error(error);
          toast.error('Si è verificato un errore imprevisto');
        });
    }
  }, [service, serviceUser, month, year]);

  return (
    <>
      <Button onClick={onOpen} {...props} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scarica Scheda ASPA ADM</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={8}>
              <FormControl as="fieldset">
                <FormLabel as="legend">Cantiere</FormLabel>
                <ServiceAutocomplete isRequired value={service} onChange={(val) => setService(val)} />
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Utente</FormLabel>
                <ServiceUserAutocomplete
                  isRequired
                  value={serviceUser}
                  isDisabled={!service}
                  serviceId={service?.id || 0}
                  onChange={(val) => {
                    setServiceUser(val);
                  }}
                />
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Mese</FormLabel>
                <InputGroup flexDirection="row" gap={4}>
                  <FormControl isRequired>
                    <Select
                      placeholder="Seleziona un mese"
                      value={month}
                      onChange={(e) => {
                        setMonth(parseInt(e.target.value));
                      }}
                    >
                      <option value="1">Gennaio</option>
                      <option value="2">Febbraio</option>
                      <option value="3">Marzo</option>
                      <option value="4">Aprile</option>
                      <option value="5">Maggio</option>
                      <option value="6">Giugno</option>
                      <option value="7">Luglio</option>
                      <option value="8">Agosto</option>
                      <option value="9">Settembre</option>
                      <option value="10">Ottobre</option>
                      <option value="11">Novembre</option>
                      <option value="12">Dicembre</option>
                    </Select>
                  </FormControl>
                  <FormControl isRequired>
                    <Select
                      placeholder="Seleziona un anno"
                      value={year}
                      onChange={(e) => {
                        setYear(parseInt(e.target.value));
                      }}
                    >
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                    </Select>
                  </FormControl>
                </InputGroup>
              </FormControl>
              <Box textAlign="center">
                <Text>La scheda verrà spedita in allegato alla tua e-mail:</Text>
                <Text fontWeight="semibold">{data?.account.email}</Text>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              leftIcon={<Icon as={FiSend} />}
              w="full"
              onClick={handleDownload}
              isLoading={reportSendLoading}
              zIndex={9}
              disabled={!(service && serviceUser && month && year)}
            >
              Invia Scheda
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const REPORT_ACCOUNT_QUERY = graphql(`
  query ReportAccount {
    account {
      email
    }
  }
`);

const REPORT_MUTATION = graphql(`
  mutation ReportSendStaffMonthlyAdm($staffEmail: String!, $serviceId: Int!, $serviceUserId: Int!, $month: Int!, $year: Int!, $aspa: Boolean!) {
    reportSendStaffMonthlyAdm(staffEmail: $staffEmail, serviceId: $serviceId, serviceUserId: $serviceUserId, month: $month, year: $year, aspa: $aspa)
  }
`);

export default ReportAdmButton;
